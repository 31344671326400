.App {
  text-align: center;
}


body {
  background-color: white;
  color: #444;
  font-family: Helvetica, sans-serif;
  margin: 0px; 
}

$gap: 15px;

#root {
  padding: 0 5px;
}

p {
  line-height: 1.5em;
}

.two-thirds {
  width: 100%;
}

.flex-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: $gap;

  //padding: 0 5px;


  .col {
    width: 100%;
    &.right-center {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &.one {
      margin-top: 30px;
    }
  }
  &.wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
h1 {
  color: #4ea699;
  margin-top: 60px;
}

    gap: $gap;// *3;
    
  }
  .col.four {
    flex: 0 0;
    min-width: 230px;
    p {
      margin-top: 10px;
    }
    img {
      width: 100%;
      box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.15);
    }
  }
}
.MuiButton-contained {
  background-color: white !important;
  color: #4ea699 !important;
  border-radius: 0px !important;
}

.footer {
  text-align: center;
  margin: 30px 0 ;
  a,a:link,a:visited,a:hover,a:active {
    color: #4ea699;
  }
}

@mixin gridWithWidth($width) {

  .flex-grid {

    .col {
      z-index: 1;

      &.one {
        width: $width;
      }

      &.two {
        width: ($width - $gap)/2;
      }

      &.three {
        width: ($width - $gap * 2)/3;
      }

      &.four {
        width: ($width - $gap * 3)/4;
      }


    }

    &.no-gap {
      gap: 0;
      .col.three {
        width: ($width)/3;
      }
    }



  }

}


.MuiButton-containedPrimary {
    color: #4ea699 !important;
    background-color: white !important;
    font-family: Helvetica, sans-serif !important;
    //font-weight: bold !important;
}

.MuiButton-label {
  font-weight: bold;
}

.center-band {
  background-color: #4ea699;
  display: flex;
  justify-content: center;
  padding: $gap*2;
  margin-top: 60px;
  margin-bottom: $gap*2;
  margin-left: -5px;
  margin-right: -5px;
}

iframe {
  width: 100%;
  border: none;
  height: 180px;
  margin-left: -1 * $gap;
  margin-bottom: -1 * $gap;
}
@mixin iframeWithHeight($height) {
  iframe {
    height: $height;
  }
}



.box {
  background-color: plum;
  color: purple;
  width: 100%;
  height: 50px;
  margin-top: 15px;
}

h1,h2,h3,h4,p {
  margin-top: $gap;
  margin-bottom: $gap;
}

h1 {
  color: darken(#4ea699,5);
  margin-top: 60px;
}


.iframe-label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: darken(#4ea699,5);
}

.mint-sub {
  color: white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: -15px;
}

.MuiButton-containedPrimary {
  border-radius: 0px !important;
}

.makeStyles-root-1 .MuiDialog-paper, .jss1 .MuiDialog-paper {
  border-radius: 0px;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiList-root, .jss1 .MuiDialogContent-root .MuiList-root {
  background-color: white !important;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiCollapse-root .MuiList-root, .jss1 .MuiDialogContent-root .MuiCollapse-root .MuiList-root {
  background-color: white !important;
}

.makeStyles-root-1 .MuiDialogTitle-root, .jss1 .MuiDialogTitle-root {
  background-color: #4ea699 !important;
  color: white !important;
}

.makeStyles-root-1 .MuiDialogTitle-root .MuiIconButton-root, .jss1 .MuiDialogTitle-root .MuiIconButton-root {
  color: white !important;
}

.section  {
  border: 1px solid #4ea699;
  color: #4ea699;
  padding: 5px 9px;
  display: inline-block;
  &.selected {

  }

}

.flex-grid.no-gap {
  display: block;
  text-align: left;
  .col.three {
    width: 330px;
    margin-left: 0px;
    margin-bottom: $gap*2;
  }
  .iframe-label {
    text-align: left;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  iframe {
    margin-left: 0px;
  }


.flex-grid.no-gap {
  display: flex;
  text-align: center;
  .col.three {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .iframe-label {
    margin-left: 0px;
    text-align: center;
  }
}

.flex-grid.wrap {
  justify-content: center;
  gap: $gap;
}


 }


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  @include gridWithWidth(768px);
  @include iframeWithHeight(180px);
  iframe {
    margin-left: 0px;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  @include gridWithWidth(992px);
  @include iframeWithHeight(180px);

  iframe {
    margin-left: -30px;
  }

  .flex-grid.no-gap .iframe-label {
    text-align: left;
  }

  .flex-grid.wrap {
    gap: $gap;
    justify-content: space-between;
  }

  .two-thirds {
    width: 66.7%;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  @include gridWithWidth(1200px);
  @include iframeWithHeight(240px);

  .iframe-label {
    text-align: left;
  }
}